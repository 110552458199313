import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import List from "./routes/list"
import Create from "./routes/create"
import Edit from "./routes/edit"
import Show from "./routes/view"

const Router = ({match}) => (
    <div className="app-wrapper">
        <Switch>
            <Route exact path={`${match.url}/create`} component={Create}/>
            <Route exact path={`${match.url}`} component={List}/>
            <Route exact path={`${match.url}/:id/edit`} component={Edit}/>
            <Route exact path={`${match.url}/:id`} component={Show}/>
            <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
        </Switch>
    </div>
);

export default Router;