import React from 'react'
import Form from '../Components/form'
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Loader from "../../../../../components/CircularProgress"
import swal from 'sweetalert';
import { get, post, update, destroy, put } from "../../../../../services/api/http.service";

export default class Create extends React.Component {

    state = {
        reward: null
    }

    getData = async (route, dataName) => {
        const response = await get(route)
        if (response.success) {
            this.setState({
                [dataName]: response.rows[0]
            })
        }
    }   

    async componentDidMount() {
        await this.getData(`rewards/${this.props.match.params.id}`, "reward")
    }

    handleSubmit = async (values, groups) => {
        try{      
            let flag = groups.filter(row => row.isSelectedInReward == true)
            values.isForUsersWithoutGroup = flag.length > 0 ? false : true
            values.groups = groups
            const response = await put("rewards/" + values.id, values)
            if (!response.success) {
                swal({
                    title: "Error",
                    text: response.message,
                    icon: "error",
                    confirmButtonColor: "#ffb133",
                })
                return
            }
            swal({
                title: "Recompensa registrada",
                text: response.message,
                icon: "success",
                confirmButtonColor: "#ffb133",
            })
            .then(() => window.location.href = "/app/rewards")
        }catch(err){
            console.log("Error: ", err)
            swal({
                title: "Error",
                text: err,
                icon: "error",
                confirmButtonColor: "#ffb133",
            })
        }
    }


    handleCancel = () => this.props.history.push("/app/rewards")

    render() {        
        return (
            this.state.reward ?
            <Card className="container-fluid p-4 pl-5">
                <h1>Registrar recompensa</h1>
                <Divider className="mb-4" />
                <Card className="col-12 p-4 pl-5 mr-4">
                    <Form onCancel={this.handleCancel} onSubmit={this.handleSubmit} handleSubmit={this.handleSubmit} reward={this.state.reward} readOnly={false}/>
                </Card>
            </Card>
            :
            <Loader />
        )
    }
}