import React from 'react'
import Form from '../Components/form'
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Loader from "../../../../../components/CircularProgress"
import { get, post, put, destroy } from "../../../../../services/api/http.service";

export default class View extends React.Component {

    state = {
        notification: null,
        notification_types: [],
        groups: []
    }

    getData = async (route, dataName) => {
        const response = await get(route)
        console.log("nunuin ", response)
        if (response.success) {
            this.setState({
                [dataName]: response.rows[0]
            })
        }
    }   

    async getNotificationTypes(){
        try{
            let response = await get("notification_types")
            if(response.success){
                //trademarks = response.rows
                this.setState({
                    ...this.state,
                    notification_types: response.rows
                });
            }
        } catch(err){
            console.log("Error: ", err)
        }
    }

    async getGroups(){
        try{
            let response = await get("groups")
            if(response.success){
                //trademarks = response.rows
                this.setState({
                    ...this.state,
                    groups: response.rows
                });
            }
        } catch(err){
            console.log("Error: ", err)
        }
    }


    async componentDidMount() {
        await this.getData(`notifications/${this.props.match.params.notificationId}`, "notification")
        await this.getNotificationTypes();
        await this.getGroups();
    }

    handleCancel = () => this.props.history.push("/app/notifications")

    render() {
        console.log(this.state)
        return (
            this.state.notification ?
                <Card className="container-fluid p-4 pl-5">
                    <h1>Ver Notificación</h1>
                    <Divider className="mb-4" />
                    <Card className="col-12 p-4 pl-5 mr-4">
                        <Form onCancel={this.handleCancel} 
                            notification={this.state.notification} notification_types={this.state.notification_types} readOnly={true} groups={this.state.groups} />
                    </Card>
                </Card>
                :
                <Loader />
        )
    }
}