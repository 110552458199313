const jwt = require("jsonwebtoken");
const crypto = require("crypto-js");
const bodyParser = require("body-parser");
require("dotenv").config()

const encryptData = async (data) => {
   
    //convert JSON to string
    let stringData = JSON.stringify(data);
    var textToEncrypt = stringData;            

    console.log(process.env.REACT_APP_ENCRYPTION_SECRET)
    console.log(process.env)
    const encrypt = function (text) {
        return (crypto.AES.encrypt(text, process.env.REACT_APP_ENCRYPTION_SECRET)).toString();
    }

    var cipherText = encrypt(textToEncrypt);
    return cipherText
}

const decipherData = async (data) => {
   //convert JSON to string
   
   //decipher data
   const decrypt = function (ciphertext) {
       var bytes  = crypto.AES.decrypt(ciphertext, process.env.REACT_APP_ENCRYPTION_SECRET);
       return bytes.toString(crypto.enc.Utf8);
   }

   //parse data
   var uncipherText = decrypt(data);
   let text = JSON.parse(uncipherText);

   return text
}

export {
  encryptData,
  decipherData
};
