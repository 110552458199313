import React from 'react'
import Form from '../Components/form'
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import { get, post, put, destroy } from "../../../../../services/api/http.service";
import swal from 'sweetalert';
import Loader from "../../../../../components/CircularProgress"

export default class Edit extends React.Component {

    state = {
        group: null
    }
    
    getData = async (route, dataName) => {
        const response = await get(route)
        if (response.success) {
            this.setState({
                [dataName]: response.rows[0]
            })
        }
    }

    handleSubmit = async (values) => {
        try{
            //poner time en 0 del datetime
            let data = Object.assign({},values)

            const response = await put(`groups/${values.id}`, data)
            console.log("=Response  ", response)
            if (!response.success) {
                swal({
                    title: "Error",
                    text: response.message,
                    icon: "error",
                    confirmButtonColor: "#ffb133",
                })
                return
            }
            swal({
                title: "Grupo editado",
                text: response.message,
                icon: "success",
                confirmButtonColor: "#ffb133",
            })
            .then(() => this.handleCancel())
        }catch(err){
            swal({
                title: "Error",
                text: err,
                icon: "error",
                confirmButtonColor: "#ffb133",
            })
        }       
    }
    
    async componentDidMount() {
        console.log("wwwwwwwwww ", this.props.match.params)
        await this.getData(`groups/${this.props.match.params.groupId}`, "group")
    }

    handleCancel = () => window.location.href = "/app/groups/"/*this.props.history.push("/app/groups")*/

    render() {
        return (
            this.state.group ?
                <Card className="container-fluid p-4 pl-5">
                    <h1>Editar Grupo</h1>
                    <Divider className="mb-4" />
                    <Card className="col-12 p-4 pl-5 mr-4">
                        <Form onCancel={this.handleCancel} onSubmit={this.handleSubmit}  handleSubmit={this.handleSubmit} 
                            group={this.state.group}/>
                    </Card>
                </Card>
                :
                <Loader />
        )
    }
}