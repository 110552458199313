import React from 'react'
import Form from '../Components/form'
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Loader from "../../../../../components/CircularProgress"
import { get, post, put, destroy } from "../../../../../services/api/http.service";

export default class View extends React.Component {

    state = {
        mission: null,
        trademarks: [],
        rewards: [],
        mission_types: [],
        status: [],
        fan_pages: []
    }

    getData = async (route, dataName) => {
        const response = await get(route)
        console.log("el dddd ", response.rows)
        if (response.success) {
            this.setState({
                [dataName]: response.rows[0]
            })
        }
    }   

    async getFanPages() {
        try {
            let response = await get("fan_pages")
            if(response.success){
                this.setState({
                    ...this.state,
                    fan_pages: response.rows.filter(fan_page => fan_page.status === true) // solo fan_pages activas
                });
            } 
        } catch (err) {
            console.log("Error: ", err)
        }
    }
    
    async getTrademarks(){
        try{
            let response = await get("trademarks")
            if(response.success){
                //trademarks = response.rows
                this.setState({
                    ...this.state,
                    trademarks: response.rows
                });
            }
        } catch(err){
            console.log("Error: ", err)
        }
    }

    async getRewards() {
        try{
            let response = await get("rewards");
            if(response.success){
                this.setState({
                    ...this.state,
                    rewards: response.rows
                });
            }
        } catch(err) {
            console.log("Error: ", err)
        }
    }

    async getMissionTypes(){
        try{
            let response = await get("mission_types")
            if(response.success){
                //trademarks = response.rows
                this.setState({
                    ...this.state,
                    mission_types: response.rows
                });
            }
        } catch(err){
            console.log("Error: ", err)
        }
    }

    async getStatus(){
        try{
            let response = await get("status")
            if(response.success){
                //trademarks = response.rows
                this.setState({
                    ...this.state,
                    status: response.rows.filter(status => status.status_typeId === 4) // Solo estatus de misiones 
                });
            }
        } catch(err){
            console.log("Error: ", err)
        }
    }

    async getSocialNetworks(){
        try {
            let response = await get("social_networks")
            if(response.success){
                this.setState({
                    ...this.state,
                    social_networks: response.rows.filter(trademark => trademark.status === true) // solo redes sociales activas
                });
            }
        } catch (err) {
            console.log("Error: ", err);
        }
    }


    async componentDidMount() {
        await this.getTrademarks()
        await this.getRewards()
        await this.getMissionTypes()
        await this.getStatus()
        await this.getFanPages()
        await this.getData(`missions/${this.props.match.params.missionId}`, "mission")
        await this.getSocialNetworks()
    }

    handleCancel = () => window.location.href = "/app/missions/"//this.props.history.push("/app/missions")

    render() {
        console.log(this.state)
        return (
            this.state.mission ?
                <Card className="container-fluid p-4 pl-5">
                    <h1>Ver Misión</h1>
                    <Divider className="mb-4" />
                    <Card className="col-12 p-4 pl-5 mr-4">
                        <Form onCancel={this.handleCancel} onSubmit={this.handleSubmit}  handleSubmit={this.handleSubmit} trademarks={this.state.trademarks} rewards={this.state.rewards} fan_pages={this.state.fan_pages}
                            mission_types={this.state.mission_types} status={this.state.status} mission={this.state.mission} readOnly={true} social_networks={this.state.social_networks} 
                            isSocialNetworkReadOnly={true} />
                    </Card>
                </Card>
                :
                <Loader />
        )
    }
}