import React, { useState, useEffect } from "react";
import swal from "sweetalert";
// Material UI
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

// Services
import { get } from "../../../../../services/api/http.service";

// Components
import Datatable from "../../../components/routes/tables/DataTable";
import RowTable from "./rowTable";

const List = () => {
  const [data, setData] = useState([]);
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(true);

  const headers = [
    { id: "name", numeric: false, disablePadding: false, label: "Nombre" },
    { id: "desc", numeric: false, disablePadding: false, label: "Descripción" },
    { id: "status", numeric: false, disablePadding: false, label: "Estatus" },
    { id: "date", numeric: false, disablePadding: false, label: "Fecha de alta" },
    { id: "actions", numeric: true, disablePadding: false, label: " " }
  ];

  useEffect(() => {
    getData();
    getPermissions();
  }, []);

  const getData = async () => {
    try {
      const tempData = await get("roles");
      if (tempData.success) {
        setData(tempData.rows);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPermissions = () => {
    let permissions = JSON.parse(localStorage.getItem('permissions'));

    if (permissions === null || permissions === undefined) {
      permissions = [];
    }
    
    let permissionTemp = permissions.filter(perm => {
      if (perm.section === 'roles') {
        return perm;
      }
    });
    console.log(permissionTemp);
    setPermissions(permissionTemp);
    setLoading(false);
  }

  return !loading && (
    <div className="container">
      <div className="row">
      {
        !permissions[0].can_read ?
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert">
              No tienes permisos para ver esta sección
            </div>
          </div> :
          <>
            <div className="col-md-3 mb-3 mt-3">
            {
              permissions[0].can_write &&
                <Button
                  variant="contained"
                  color="primary">
                  <Link className="text-white" to={`/app/roles/create`}>
                    Añadir rol
                  </Link>
                </Button>
              }
            </div>
            <div className="col-md-12 mr-2">
              <Datatable
                RowComponent={RowTable}
                data={data}
                headers={headers}
                title="Roles de usuario"
              />
            </div>
          </>
      }        
      </div>
    </div>
  );
};

export default List;
