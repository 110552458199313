import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./dashboard";
import Widgets from "./widgets";
import Metrics from "./metrics";
import Components from "./components";
import Icons from "./icons";
import Form from "./form";
import Editors from "./editors";
import Pickers from "./pickers";
import Extensions from "./extensions";
import Table from "./table";
import Chart from "./charts";
import Map from "./map";
import Calendar from "./calendar";
import TimeLine from "./timeLine";
import CustomViews from "./customViews";
import ExtraElements from "./extraElements";
import eCommerce from "./eCommerce";
import AppModule from "./appModule";
import ExtraPages from "./extraPages";
import asyncComponent from "../../util/asyncComponent";
import SocialApps from "./socialApps";
import { withRouter } from "react-router";
import MenuLevels from "./menuLevels";
import UsersView from "./users";
import AppUsersView from "./app_users";
import Missions from "./missions";
import MissionsCreate from "./missions/routes/create";
import MissionsTypesView from "./missionsTypes";
import Clients from "./clients";
import Refunds from "./refunds";
import Facebook from "./facebook";
import GroupsView from "./groups";
import Notifications from "./notifications";
import FanPages from "./fan_pages";
import Rewards from "./rewards";
import Roles from "./roles";
import RolesCreate from "./roles/routes/create";
import RolesEdit from "./roles/routes/edit";
import RolesShow from "./roles/routes/view";

const Routes = ({ match }) =>
  <Switch>
    <Route path={`${match.url}/dashboard`} component={Dashboard}/>
    <Route path={`${match.url}/social-apps`} component={SocialApps}/>
    <Route path={`${match.url}/components`} component={Components}/>
    <Route path={`${match.url}/icons`} component={Icons}/>
    <Route path={`${match.url}/form`} component={Form}/>
    <Route path={`${match.url}/editor`} component={Editors}/>
    <Route path={`${match.url}/pickers`} component={Pickers}/>
    <Route path={`${match.url}/extensions`} component={Extensions}/>
    <Route path={`${match.url}/table`} component={Table}/>
    <Route path={`${match.url}/chart`} component={Chart}/>
    <Route path={`${match.url}/map`} component={Map}/>
    <Route path={`${match.url}/calendar`} component={Calendar}/>
    <Route path={`${match.url}/time-line`} component={TimeLine}/>
    <Route path={`${match.url}/custom-views`} component={CustomViews}/>

    <Route path={`${match.url}/widgets`} component={Widgets}/>
    <Route path={`${match.url}/metrics`} component={Metrics}/>
    <Route path={`${match.url}/extra-elements`} component={ExtraElements}/>
    <Route path={`${match.url}/ecommerce`} component={eCommerce}/>
    <Route path={`${match.url}/app-module`} component={AppModule}/>
      <Route path={`${match.url}/menu-levels`} component={MenuLevels}/>
    <Route path={`${match.url}/to-do`}
           component={asyncComponent(() => import("./todo/basic"))}/>
    <Route path={`${match.url}/to-do-redux`}
           component={asyncComponent(() => import("./todo/redux"))}/>
    <Route path={`${match.url}/mail`}
           component={asyncComponent(() => import("./mail/basic"))}/>
    <Route path={`${match.url}/mail-redux`}
           component={asyncComponent(() => import("./mail/redux"))}/>
    <Route path={`${match.url}/chat`}
           component={asyncComponent(() => import("./chatPanel/basic"))}/>
    <Route path={`${match.url}/chat-redux`}
           component={asyncComponent(() => import("./chatPanel/redux"))}/>
    <Route path={`${match.url}/contact`}
           component={asyncComponent(() => import("./contact/basic"))}/>
    <Route path={`${match.url}/contact-redux`}
           component={asyncComponent(() => import("./contact/redux"))}/>
    <Route path={`${match.url}/extra-pages`} component={ExtraPages}/>    
    {/*codigo fuera de la plantilla*/}
    <Route path={`${match.url}/usuarios`} component={UsersView}/>

    <Route path={`${match.url}/app_users`} component={AppUsersView}/>

    <Route path={`${match.url}/roles`} component={Roles}/>
    <Route path={`${match.url}/roles/create`} component={RolesCreate}/>
    <Route path={`${match.url}/roles/:roleId`} component={RolesShow}/>
    <Route path={`${match.url}/roles/:roleId/edit`} component={RolesEdit}/>

    {/* misiones routes */}     
    <Route path={`${match.url}/missions`} component={Missions}/>
    
       
    
    {/* misiones types routes */}   
    <Route path={`${match.url}/misiones_tipos`} component={MissionsTypesView}/>

    {/* trademarjs routes*/}
    <Route path={`${match.url}/clients`} component={Clients}/>

    {/* facebook routes*/}
    <Route path={`${match.url}/facebook`} component={Facebook}/>
    
    {/* groups routes */}
    <Route path={`${match.url}/groups`} component={GroupsView}/>

    {/* notifications routes */}
    <Route path={`${match.url}/notifications/create`} component={asyncComponent(() => import("./notifications/routes/create"))}/>
    <Route path={`${match.url}/notifications/:notificationId`} component={asyncComponent(() => import("./notifications/routes/view"))}/>    
    <Route path={`${match.url}/notifications`} component={Notifications}/>

    {/* FanPages */}
    <Route path={`${match.url}/fan_pages`}
           component={asyncComponent(() => import("./fan_pages"))}/>

       <Route path={`${match.url}/close`} component={asyncComponent(() => import("./x"))}/>    

    {/* rewards */}
    <Route path={`${match.url}/rewards/create`} component={asyncComponent(() => import("./rewards/routes/create"))}/>
    <Route path={`${match.url}/rewards/:id/edit`} component={asyncComponent(() => import("./rewards/routes/edit"))}/>   
    {/*<Route path={`${match.url}/rewards/:id/rewards_material`} component={asyncComponent(() => import("./rewards/routes/rewards_material"))}/>   */}
    <Route path={`${match.url}/rewards/:id`} component={asyncComponent(() => import("./rewards/routes/view"))}/>    
    <Route path={`${match.url}/rewards`} component={Rewards}/>

    {/* refunds routes*/}
    <Route path={`${match.url}/refunds`} component={Refunds}/>

    <Route component={asyncComponent(() => import("app/routes/extraPages/routes/404"))}/>

    

  </Switch>;


export default withRouter(Routes);
