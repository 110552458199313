import React, { useState, useEffect } from "react";
import swal from "sweetalert";
// Material UI
import { Button } from "@material-ui/core";

// Services
import { get, post, update, destroy } from "../../../../../services/api/http.service";
import Form from "../components/form"
import { encryptData, decipherData } from "../../../../../services/api/Security.js"

// Components
import Datatable from "../../../components/routes/tables/DataTable";
import RowTable from "./rowTable";

const List = () => {
  const [data, setData] = useState([]);
  const [showEdit, setShowEdit] = useState(false)
  const [showCreate, setShowCreate] = useState(false)
  const [showView, setShowView] = useState(false)
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(true);

  const headers = [
    { id: "name", numeric: false, disablePadding: false, label: "Nombre" },
    { id: "status", numeric: false, disablePadding: false, label: "Estatus" },    
    { id: "actions", numeric: true, disablePadding: false, label: "Acciones" }
  ];

  useEffect(() => {
    getData();
    getPermissions();
  }, []);

  const getData = async () => {
    try {
      const tempData = await get("clients");
      setData(tempData.clients);
    } catch (err) {
      console.log(err);
    }
  };

  const getPermissions = () => {
    let permissions = JSON.parse(localStorage.getItem('permissions'));

    if (permissions === null || permissions === undefined) {
      permissions = [];
    }
    
    let permissionTemp = permissions.filter(perm => {
      if (perm.section === 'client') {
        return perm;
      }
    });
    console.log(permissionTemp);
    setPermissions(permissionTemp);
    setLoading(false);
  }

  const register = async (form) => {
    console.log("Register params: ", form);
    const body = {
      ...form,
    };
    try{
      let response = await post("clients", body)
      console.log("Response: ", response);
      swal({
        title: "Creación exitosa",
        text: "Marca/Empresa creada correctamente.",
        icon: "success",
        confirmButtonColor: "#ffb133",
      })
      .then(() => {
        getData()
        //window.location.reload(false);
      });
    }catch(err){
      swal({
        title: "Error",
        text: `Ups, hubo un problema \n ${err}`,
        icon: "error",
        confirmButtonColor: "#ffb133",
      })
    }
  };

  const setModalState = (name, open) => {
    console.log("dentro del set modal")
    switch(name){
      case "Create":
        console.log("dentro del create modal ", name , " ", open)
          setShowCreate(open)
        break;
      case "Edit":
        setShowEdit(open)
        break;
      case "View":
        setShowView(open)
        break;
      default:
        break;
    }
  }

  return !loading && (
    <>
      <div className="container">
        <div className="row">
          {
            !permissions[0].can_read ?
            <div className="col-md-12">
              <div className="alert alert-danger" role="alert">
                No tienes permisos para ver esta sección
              </div>
            </div> :
            <> 
              <div className="col-md-3 mb-3 mt-3">
                {
                  permissions[0].can_write &&
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => { setModalState("Create", true) }}
                  >Crear cliente comercial</Button>
                }
              </div>
              <div className="col-md-12 mr-2">
                <Datatable
                  RowComponent={RowTable}
                  setModal={setModalState}
                  data={data}
                  headers={headers}
                  title="Clientes comerciales"
                />
              </div>
            </>
          }
        </div>
      </div>
      {/* ***************************  CRUD Popup's **************************** */}

      {/* Create */}
      <Form 
        show={showCreate}
        title={"Registrar cliente comercial"}
        onSave={register}
        readOnly={false}
        onClose={() => { setModalState("Create", false) }}
      />
    </>
  );
};

export default List;
