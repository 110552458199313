import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { post } from "../services/api/http.service";
import swal from 'sweetalert';

class RecoveryPassword extends Component {

  constructor(props) {
    super(props)
    const { match: { params } } = props;

    this.state = { 
      token: params.token,
      password: '',
      confirmationPassword: '',
      loader: true,
      isValid: false,
      invalidMessage: '',
      disabled: true,

      //icons
      minCharsColor: '#CBCBCB',
      minCharsValue: false,
      differentCharsColor: '#CBCBCB',
      differentCharsValue: false,
      noSecuenciaColor: '#CBCBCB',
      noSecuenciaValue: false,
      noCharSecuenciaColor: '#CBCBCB',
      noCharSecuenciaValue: false,
      noEmailColor: '#CBCBCB',
      noEmailValue: false,
    }        
  }

  async componentDidMount() {
    await this.validateToken();
  }

  async validateToken() {
    let data = {
      token: this.state.token
    }

    let response = await post("alpha/token/validate", data)
    
    if(response.success) {
      this.setState({
        isValid: response.validation,
        invalidMessage: response.message,
        loader: false
      });
    }
  }

  changePassword = (values) => {
    this.setState({
      password: values
    }, () => {
      this.checkCharacterCount();
    })
  }

  changeConfirmationPassword = (value) => {
    this.setState({
      confirmationPassword: value,
    }, () => {
      this.checkFinalForm();
    });
  }

  checkFinalForm() {
    if (this.state.minCharsValue && this.state.differentCharsValue && this.state.noSecuenciaValue && this.state.noCharSecuenciaValue && this.state.noEmailValue) {
      this.setState({
        disabled: false
      })
    }
  }

  checkCharacterCount() {
    const inputValue = this.state.password;

    if (inputValue === '') {
      this.setState({
        minCharsColor: '#CBCBCB',
        differentCharsColor: '#CBCBCB',
        noSecuenciaColor: '#CBCBCB',
        noCharSecuenciaColor: '#CBCBCB',
        noEmailColor: '#CBCBCB',
      });
      return;
    }

    if (inputValue.length >= 8) {
      this.setState({
        minCharsColor: '#FFB133',
        minCharsValue: true,
      });
    } else {
      this.setState({
        minCharsColor: '#CBCBCB',
        minCharsValue: false,
      });
    }

    const hasUppercase = /[A-Z]/.test(inputValue);
    const hasNumber = /\d/.test(inputValue);
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(inputValue);

    if (hasUppercase && hasNumber && hasSpecialChar) {
      this.setState({
        differentCharsColor: '#FFB133',
        differentCharsValue: true,
      });
    } else {
      this.setState({
        differentCharsColor: '#CBCBCB',
        differentCharsValue: false,
      });
    }

    const isConsecutiveSequence = /(123|234|345|456|567|678|789)|(abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmnop|mnop|nopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz)/i.test(inputValue);
    if (isConsecutiveSequence) {
      this.setState({
        noSecuenciaColor: '#CBCBCB',
        noSecuenciaValue: false,
      });
    } else {
      this.setState({
        noSecuenciaColor: '#FFB133',
        noSecuenciaValue: true,
      });
    }

    const containsSimilarCharacters = /(.)\1{2,}/.test(inputValue);
    if (containsSimilarCharacters) {
      this.setState({
        noCharSecuenciaColor: '#CBCBCB',
        noCharSecuenciaValue: false,
      });
    } else {
      this.setState({
        noCharSecuenciaColor: '#FFB133',
        noCharSecuenciaValue: true,
      });
    }

    const hasEmailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
    if (hasEmailPattern.test(inputValue)) {
      this.setState({
        noEmailColor: '#CBCBCB',
        noEmailValue: false,
      });
    } else {
      this.setState({
        noEmailColor: '#FFB133',
        noEmailValue: true,
      });
    }
  }

  async handlesSubmit() {
    const {
      password, confirmationPassword
    } = this.state;

    this.setState({
      disabled: true
    })

    if (password !== confirmationPassword) {
      swal({
          title: "Error!",
          text: "Las contraseñas no son iguales",
          icon: "error",
          confirmButtonColor: "#ffb133",
      })
      this.setState({
        disabled: false
      })
      return;
    }

    let data = {
      password: password
    }

    let response = await post("alpha/password/" + this.state.token, data)

    if (response.success) {
      swal({
          title: "¡Hecho!",
          text: response.message,
          icon: "success",
          confirmButtonColor: "#ffb133",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.setState({
              isValid: false,
              invalidMessage: 'Para continuar, inicie sesión en la App de su nueva contraseña',
            })
          } 
        })
    } else {
      swal({
          title: "Error",
          text: response.message,
          icon: "error",
          confirmButtonColor: "#ffb133",
      })
      this.setState({
        disabled: false
      })
    }

  }

  render() {
    let {
      password, 
      confirmationPassword, 
      loader, 
      isValid, 
      invalidMessage, 
      minCharsColor, 
      differentCharsColor, 
      noSecuenciaColor, 
      noCharSecuenciaColor, 
      noEmailColor,
      disabled
    } = this.state;

    return !loader ? (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          {
            !isValid ? <div className="app-password-content">
              <div className="app-login-header">
                <h1>{invalidMessage}</h1>
              </div>
            </div> :
            <div className="app-password-content">
              <div className="app-login-header">
                <h1>Cambiar contraseña</h1>
                <p>Te mandaremos instrucciones por correo electrónico para que cambies de contraseña</p>
              </div>

              <div className="app-login-form">
                <form>
                  <TextField
                    type="password"
                    onChange={(event) => this.changePassword(event.target.value)}
                    label="Contraseña"
                    fullWidth
                    defaultValue={password}
                    margin="normal"
                    className="mt-0 mb-4"
                  />

                  <TextField
                    type="password"
                    onChange={(event) => this.changeConfirmationPassword(event.target.value)}
                    label="Confirmar contraseña"
                    fullWidth
                    defaultValue={confirmationPassword}
                    margin="normal"
                    className="mt-0 mb-4"
                  />

                  <div className='d-flex flex-column align-items-start justify-content-center'>
                    <div className="d-flex justify-content-center">
                      <svg className="min" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                              fill={minCharsColor} id="min" />
                      </svg>
                      <p id="characterCheck">Mínimo 8 caracteres</p>
                    </div>
                    <div className="d-flex justify-content-center">
                        <svg className="composition" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                                fill={differentCharsColor} id="composition" />
                        </svg>
                        <p id="characterCheck">Al menos 1 letra, 1 número y 1 carácter especial</p>
                    </div>
                    <div className="d-flex justify-content-center">
                        <svg className="sequence" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                                fill={noSecuenciaColor} id="sequence" />
                        </svg>
                        <p id="characterCheck">No puede tener una secuencia (ej: 123, abcd)</p>
                    </div>
                    <div className="d-flex justify-content-center">
                        <svg className="similar" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                                fill={noCharSecuenciaColor} id="validation" />
                        </svg>
                        <p id="characterCheck">No puede tener 3 caracteres iguales seguidos</p>
                    </div>
                    <div className="d-flex justify-content-center">
                        <svg className="notEmail" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                                fill={noEmailColor} id="notEmail" />
                        </svg>
                        <p id="characterCheck">No puede tener tu email</p>
                    </div>
                  </div>

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button variant="contained" onClick={() => this.handlesSubmit()} color="primary" disabled={disabled}>
                      Cambiar contraseña
                    </Button>
                  </div>

                </form>
              </div>
            </div>
          }
        </div>
      </div>
    ) : (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="loader-view">
            <CircularProgress/>
          </div>
        </div>
      </div>
    )
  }
};


export default RecoveryPassword;
