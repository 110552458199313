import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import swal from "sweetalert";

import Form from '../components/form'
import { get, put } from "../../../../../services/api/http.service";

import { useParams } from 'react-router-dom';

const Show = () => {
    const { id } = useParams();
    const [role, setRole] = useState(null)

    const handleSubmit = async (values) => {
        
    }

    const fetchData = async () => {
        const response = await get(`roles/${id}`);
        if (response.success) {
            setRole(response.rows);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        role === null || role === undefined ? 
        <Card className="container-fluid p-4 pl-5"></Card> :
        <Card className="container-fluid p-4 pl-5">
            <div className='d-flex m-4 align-middle'>
                <h1>Rol - {role.name}</h1>
            </div>
            <div className='ml-5 mr-5'>                
                <Form onHandleSubmit={handleSubmit} data={role} readOnly={true}/>
            </div>
        </Card>
    );
}

export default Show;