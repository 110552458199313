import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import List from "./routes/list"
import Create from "./routes/create"
import View from './routes/view'
import Edit from './routes/edit'

let permissions = JSON.parse(localStorage.getItem('permissions'));

if (permissions === null || permissions === undefined) {
  permissions = [];
}
    
let permission = permissions.filter(perm => {
  if (perm.section === 'notification') {
    return perm;
  }
});

const Router = ({match}) => (
  <div className="app-wrapper">
    <Switch>
      <Route path={`${match.url}/create`} component={Create}/>
      <Route path={`${match.url}/`} component={List}/>
      <Route path={`${match.url}/:notificationId`} component={View} ></Route> 
      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default Router;