import React, { Component } from "react";
import { Link } from 'react-router-dom'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Formik } from 'formik'
import * as Yup from 'yup'
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import swal from 'sweetalert';
import Checkbox from '@material-ui/core/Checkbox';
import DateFnsUtils from '@date-io/date-fns';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import { Typography, FormGroup, FormControlLabel } from '@material-ui/core';
import { get, post, update, destroy } from "../../../../../services/api/http.service";

const requiredField = "Campo Requerido"
const positiveNumber = "Número debe ser positivo"

let todayDate = new Date()

class DataDisplayer extends Component {

  constructor(props) {
    super(props)
    if (props.fan_page) {
      const { fan_page } = props
      this.state = { fan_page }
      this.state = {
        fan_page : {
          ...fan_page
        }
      }
    } else {
      this.state = {
        fan_page: {
          name: "",
          url: "",
          idFacebook: "",
          desciption: "",
          pageToken: "",
          status: true,
          userToken: "",
          trademark: "",
          trademarks_id: 0
        }
      }
    }   
  }

  handleSubmit = this.props.onSubmit

  handleCancel = (values) => {
    this.props.onCancel(values)
  }

  render(){
    const { fan_page } = this.state;
    const { trademarks } = this.props;

    return (
      <Formik
        initialValues={fan_page}
        onSubmit={this.handleSubmit}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required(requiredField),
          url: Yup.string()
            .required(requiredField)
        })}
      >
                
        {props => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          } = props

          return (
            <form className="container-fluid" autoComplete='off' onSubmit={handleSubmit}>              
              <div className="row mb-md-3">
                <div className="col-lg-6 col-sm-6 col-12">
                  <TextField
                    fullWidth
                    id="outlined-text-input"
                    label="Título"
                    type="text"
                    name="name"
                    inputProps={{ maxLength: 128, readOnly: this.props.readOnly }}
                    onBlur={handleBlur}
                    error={errors.name && touched.name}
                    helperText={touched.name && (errors.name)}
                    className={errors.name && touched.name && "error"}
                    value={values.name}
                    onChange={handleChange}
                  />
                 </div> 
                 <div className="col-lg-6 col-sm-6 col-12">
                    <TextField
                        fullWidth
                        id="outlined-text-input"
                        label="Título"
                        type="text"
                        name="url"
                        inputProps={{ maxLength: 128, readOnly: this.props.readOnly }}
                        onBlur={handleBlur}
                        error={errors.url && touched.url}
                        helperText={touched.url && (errors.url)}
                        className={errors.url && touched.url && "error"}
                        value={values.url}
                        onChange={handleChange}
                    />
                 {/* <FormControl fullWidth>
                      <InputLabel>Marca</InputLabel>
                      <Select
                        fullWidth
                        id="outlined-text-input"
                        label="Marca comercial"
                        type="text"
                        name="trademarks_id"
                        inputProps={{ maxLength: 128, readOnly: this.props.readOnly || values.statusId == 2}}
                        onBlur={handleBlur}
                        error={errors.trademarks_id && touched.trademarks_id}
                        helperText={touched.trademarks_id && (errors.trademarks_id)}
                        className={errors.trademarks_id && touched.trademarks_id && "error"}
                        value={values.trademarks_id}
                        onChange={handleChange}
                      >
                      { trademarks ? trademarks.map( (trademark) => {
                        return (<MenuItem value={trademark.id}> {trademark.name} </MenuItem>)
                      }) : null
                      }
                    </Select>
                  </FormControl>
                   */}
                 </div>
              </div>
    
              {this.props.readOnly &&
                <div className="row justify-content-center mt-4 pl-4">
                  <Button className='btn mr-3' style={{ backgroundColor: '#D2181F', color: 'white' }} onClick={() => { this.handleCancel(values) }} >Regresar</Button>
                </div>}
              {(!this.props.readOnly && (<div className="row justify-content-center mt-4 pl-4">
                <Button disabled={isSubmitting} className='btn mr-3' style={{ backgroundColor: '#D2181F', color: 'white' }} onClick={() => { this.handleCancel(values) }} >
                  Cancelar
                                    </Button>
                <Button disabled={isSubmitting} className='btn' style={{ backgroundColor: '#4E6E58', color: 'white' }} type="submit" onClick={() => { this.handleSubmit(values, 
                                                                                                                                                      this.state.groups, this.state.allGroups,
                                                                                                                                                       this.state.withoutGroups) } } >
                  {isSubmitting && <span className="spinner-border spinner-border-sm"></span>}
                                    Continuar
                                    </Button>
              </div>))}
            </form>
          )
        }}
      </Formik>
    );
  }
}

export default DataDisplayer;