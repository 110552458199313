import React, { useState, useEffect } from "react";
import {
  Menu,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  InputLabel,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { encryptData, decipherData } from "../../../../../services/api/Security.js"
import { get, post, put, destroy } from "../../../../../services/api/http.service";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Options = (props) => {
  const classes = useStyles();
  const { menuState, anchorEl, handleRequestClose, data } = props;
  const [dataRoles, setDataRoles] = useState([]);
  //const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  //const [showModalView, setShowModalView] = useState(false);
  const [formUpdate, setFormUpdate] = useState();
  const [stateActive, setStateActive] = useState(
    data.statusId == 1 ? true : false
  );
  const [roleId, setRoleId] = useState(data.roleId);
  const [clients, setClient] = useState([]);
  const [clientId, setClientId] = useState(data.client_id);

  const handleUserActiveChange = (event) => {
    setStateActive(event.target.checked);
    //console.log("userActive", userActive);
    //console.log("Estado checkbox", stateActive);
  };

  const updateDataUser = (e) => {
    console.log("FORM UPDATE", props);
    setFormUpdate({
      ...formUpdate,
      [e.target.name]: e.target.value,
    });
  };

  const getClients = async () => {
    try {
      const tempData = await get("clients");
      console.log(tempData);
      setClient(tempData.clients);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUserUpdate = async () => {
    closeEditModal();
    const updateFormData = {
      ...formUpdate,
      roleId: roleId,
      statusId: stateActive == true ? 1 : 2,
      client_id: clientId
    };
    console.log("Data", data);
    console.log("dataparams", updateFormData);
    try{
      let chiperData = await encryptData(updateFormData)
      let bodyChiper = {data: chiperData}
      console.log("users/" + data.id,)
      let response = await put("users/" + data.id, bodyChiper)
      console.log("Update response: ", response)
      if(response.success){
        swal({
            title: "Usuario Actualizado",
            text: "El usuario fue actualizado exitosamente.",
            icon: "success",
            confirmButtonColor: "#ffb133",
        })
        .then(() =>{
          window.location.reload(false);
        })
      } else {
        swal({
            title: "Error",
            text: response.message,
            icon: "error",
            confirmButtonColor: "#ffb133",
        })
      }

    }catch(err){
      swal({
          title: "Error",
          text: `Ups, hubo un problema \n ${err}`,
          icon: "error",
          confirmButtonColor: "#ffb133",
      })
    }
  };

  const openEditModal = () => {
    setShowModalEdit(true);
  };

  const closeEditModal = () => {
    setShowModalEdit(false);
  };

  // const openViewModal = () => {
  //   setShowModalView(true);
  // };

  // const closeViewModal = () => {
  //   setShowModalView(false);
  // };
  useEffect(() => {

    const getDataRolesEffect = async () => {
      try {
        const tempRolesData = await get("roles");
        console.log("tempDataRoles", tempRolesData);
        if(tempRolesData.success){
          setDataRoles(tempRolesData.rows);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getDataRolesEffect();
    getClients();
  }, []);

  return (
    <React.Fragment>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={menuState}
        onClose={handleRequestClose}
        MenuListProps={{
          style: {
            width: 150,
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <MenuItem onClick={openEditModal}>
          <i className="zmdi zmdi-edit zmdi-hc-fw" />
          <span className="ml-2">Editar</span>
        </MenuItem>
        {/* <MenuItem onClick={openViewModal}>
          <i className="zmdi zmdi-money zmdi-hc-fw" />
          <span className="ml-2">Modificar Saldo</span>
        </MenuItem> */}
      </Menu>
      <Dialog
        open={showModalEdit}
        TransitionComponent={Slide}
        onClose={closeEditModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Editar Usuario</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            size="small"
            margin="normal"
            defaultValue={data.username}
            id="nombre"
            label="Nombre"
            type="text"
            fullWidth
            name="username"
            onChange={updateDataUser}
          />
          <TextField
            autoFocus
            required
            size="small"
            margin="dense"
            id="mail"
            defaultValue={data.email}
            label="Email"
            type="email"
            fullWidth
            name="email"
            onChange={updateDataUser}
          />

          <TextField
            autoFocus
            required
            size="small"
            margin="dense"
            id="contraseña"
            defaultValue={data.password}
            label="Contraseña"
            type="password"
            fullWidth
            name="password"
            onChange={updateDataUser}
          />
          <TextField
            autoFocus
            required
            size="small"
            margin="dense"
            id="contraseñaConfirmación"
            //defaultValue={data.password}
            label="Confirmar contraseña"
            type="password"
            fullWidth
            name="password"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={stateActive}
                onChange={handleUserActiveChange}
                name="active"
                color="primary"
              />
            }
            label="Usuario Activo"
          />
          <FormControl className={classes.formControl}>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
              Rol
            </InputLabel>
            <Select
              onChange={(e) => {
                setRoleId(e.target.value);
              }}
              value={data.roleId}
            >
              {!(typeof dataRoles == "undefined") &&
                dataRoles.map((role) => {
                  return (
                    <MenuItem key={role.id} value={roleId}>
                      {role.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>

          {
            localStorage.getItem("admin") == 1 &&
            <FormControl className={classes.formControl}>
              <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                Cliente
              </InputLabel>
              <Select
                onChange={(e) => {
                  console.log(e.target.value);
                  setClientId(e.target.value);
                }}
                value={clientId}
              >
                {!(typeof clients == "undefined") &&
                  clients.map((client) => {
                    return (
                      <MenuItem key={client.id} value={client.id}>
                        {client.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          }
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeEditModal}
            color="primary"
            variant="contained"
            className="bg-danger text-white"
          >
            Cerrar
          </Button>
          <Button
            onClick={handleUserUpdate}
            color="primary"
            variant="contained"
            className="bg-success text-white"
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Options;
