import React, { useState, useEffect } from "react";
import FacebookLogin from 'react-facebook-login';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useWindowsWidth = () => {
  const [accessToken, setAccessToken] = useState(null);

    const responseFacebook = async (response) => {

        try{
            if(typeof response.accessToken != "undefined" && response.accessToken != null){
                let largeTokenResponse = await fetch("https://graph.facebook.com/v8.0/oauth/" + "access_token" + "?grant_type=fb_exchange_token&client_id=" + process.env.REACT_APP_FACEBOOK_CLIENT_ID + "&client_secret=" + process.env.REACT_APP_FACEBOOK_CLIENT_SECRET + "&fb_exchange_token=" + response.accessToken);
                let largeToken = await largeTokenResponse.json()

                if(typeof largeToken.access_token != "undefined" && largeToken.access_token != null){
                    setAccessToken(largeToken.access_token)
                }
            }

        }catch(err){
            console.log("error: ", err)
        }
    }
  
    const componentClicked = event => {
        console.log("que onda: ", event)
    }


 return (
  <>
    <div className="app-wrapper">
        <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
            autoLoad={true}
            fields="name,email,picture"
            language="es-mx"
            //scope="public_profile,user_friends,user_actions.books"
            scope="pages_show_list, pages_read_engagement, pages_read_user_content, public_profile" //pages_manage_posts
            //onClick={componentClicked}
            callback={responseFacebook} 
        />
        <p></p>
        {accessToken != null ? <FormControl fullWidth>
            <InputLabel className="pl-2"></InputLabel>
            <TextareaAutosize
                readOnly={true}
                value={accessToken}
                name="description"
                type="text"
                rowsMin={4}
                maxLength={512}
            />
        </FormControl>: null}   
    </div>   
    </>
    );
};

export default useWindowsWidth;