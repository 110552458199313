import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import List from "./routes/list"

let permissions = JSON.parse(localStorage.getItem('permissions'));

if (permissions === null || permissions === undefined) {
  permissions = [];
}

let permission = permissions.filter(perm => {
    if (perm.section === 'notification') {
    return perm;
    }
});

const AppUsersView = ({match}) => (
  
    <div className="app-wrapper">
    <Switch>
      {/*<Redirect exact from={`${match.url}/`} to={`${match.url}/crm`}/>
      <Route path={`${match.url}/crm`} component={asyncComponent(() => import('./routes/CRM'))}/>
      <Route path={`${match.url}/listing`} component={asyncComponent(() => import('./routes/Listing'))}/>
      <Route path={`${match.url}/crypto`} component={asyncComponent(() => import('./routes/Crypto'))}/>
      <Route path={`${match.url}/eCommerce`} component={asyncComponent(() => import('./routes/ECommerce'))}/>
      <Route path={`${match.url}/news`} component={asyncComponent(() => import('./routes/News'))}/>
      <Route path={`${match.url}/intranet`} component={asyncComponent(() => import('./routes/Intranet'))}/>
      <Route path={`${match.url}/misc`} component={asyncComponent(() => import('./routes/Misc'))}/>
      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>*/}
      <Route path={`${match.url}/`} component={List}/>
      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default AppUsersView;