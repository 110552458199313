import React from 'react'
import Form from '../Components/form'
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Loader from "../../../../../components/CircularProgress"
import { get, post, put, destroy } from "../../../../../services/api/http.service";

export default class View extends React.Component {

    state = {
        group: null
    }

    getData = async (route, dataName) => {
        const response = await get(route)
        if (response.success) {
            this.setState({
                [dataName]: response.rows[0]
            })
        }
    }   


    async componentDidMount() {
        console.log("ijunhuynuibnui ",this.props.match.params )
        await this.getData(`groups/${this.props.match.params.groupId}`, "group")
    }

    handleCancel = () => this.props.history.push("/app/groups")

    render() {
        console.log(this.state)
        return (
            this.state.group ?
                <Card className="container-fluid p-4 pl-5">
                    <h1>Ver Grupo</h1>
                    <Divider className="mb-4" />
                    <Card className="col-12 p-4 pl-5 mr-4">
                        <Form onCancel={this.handleCancel} 
                            group={this.state.group} readOnly={true} />
                    </Card>
                </Card>
                :
                <Loader />
        )
    }
}