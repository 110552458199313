// React
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { encryptData, decipherData } from "../../../../../services/api/Security.js"

// Material UI
import {
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import { Row, Col } from "reactstrap";

// Utils
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";

// Services
import { get, post, update, destroy } from "../../../../../services/api/http.service";
import { first } from "@amcharts/amcharts4/.internal/core/utils/Array";

const CreateMissionTypeForm = (props) => {

  const [name, setName] = useState("");
  const [stateActive, setStateActive] = useState(false);

  const onSaveHandler = async () => {
    props.onClose();
    const updateFormData = {
      name: name,
      status: stateActive == true ? 1 : 2,
    };
    try{

      let response = await post("mission_types", updateFormData)
      console.log("wwwwyeyeyey ", response)
      if(response.success){
        swal({
          title: "Creación exitosa",
          text: "El tipo de misión fue creado exitosamente.",
          icon: "success",
          confirmButtonColor: "#ffb133",
        })
        .then(() => {
          window.location.reload(false);
        });
      } else {
        swal({
          title: "Error",
          text: `Ups, hubo un problema \n ${response.message}`,
          icon: "error",
          confirmButtonColor: "#ffb133",
        })
      }
    }catch(err){
      swal({
        title: "Error",
        text: `Ups, hubo un problema \n ${err}`,
        icon: "error",
        confirmButtonColor: "#ffb133",
      })
    }

  };

  const handleUserActiveChange = (event) => {
    setStateActive(event.target.checked);
  };

  const handleUserName = (event) => {
    setName(event.target.value);
  }

  return (
    <>
      <Dialog
        open={props.show}
        TransitionComponent={Slide}
        onClose={props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Crear Tipo de Misión</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            size="small"
            margin="normal"
            id="username"
            label="Nombre"
            type="text"
            fullWidth
            name="username"
            onChange={handleUserName}
          />
          
          <FormControlLabel
            control={
              <Checkbox
                checked={stateActive}
                onChange={handleUserActiveChange}
                name="active"
                color="primary"
              />
            }
            label="Status"
          />
          
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="contained"
            onClick={props.onClose}
            color="primary"
            className="bg-danger text-white"
          >
            Cerrar
          </Button>
          { (name !== "") &&
          stateActive ?
           (
            <Button
              variant="contained"
              onClick={onSaveHandler}
              color="primary"
              className="bg-success text-white"
            >
              Guardar
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={onSaveHandler}
              style={{
                backgroundColor: "#C0C0C0",
            }}
              disabled
              className="text-white"
            >
              Guardar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateMissionTypeForm;