// React
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  encryptData,
  decipherData,
} from "../../../../../services/api/Security.js";

// Material UI
import {
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import { Row, Col } from "reactstrap";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

// Utils
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";

// Services
import {
  get,
  post,
  update,
  destroy,
} from "../../../../../services/api/http.service";
import { first } from "@amcharts/amcharts4/.internal/core/utils/Array";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CreateUserForm = (props) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    usedRewards: 0,
    availableRewards: 0,
    instructions: null
  });
  const [dataRoles, setDataRoles] = useState([]);
  const [stateActive, setStateActive] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const [firstPassword, setFirstPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(false);

  const handleInputsChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const isEnabled = () => {
    if (firstPassword == verifyPassword) {
      setButtonEnabled(true);
    }
  };

  const onSubmit = event => {
    event.preventDefault()
    props.onClose()
    props.onSubmit(formData)
  }

  useEffect(() => {
    if (props.show == true) {
      getRewardMaterial();
    }
  }, [props.show]);

  const getRewardMaterial = async () => {
    try {
      const response = await get("rewards_material/reward/" + props.id);
      if (response.success && response.rows.length > 0) {
        setFormData(response.rows[0]);
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  return (
    <>
      <Dialog
        open={props.show}
        TransitionComponent={Slide}
        onClose={props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Recompensas materiales</DialogTitle>
        <DialogContent>
          <div className="row mb-md-3">
            <div className="col-lg-6 col-sm-6 col-12">
              <TextField
                fullWidth
                label="No. recompensas apartadas"
                type="number"
                inputProps={{ readOnly: true }}
                value={formData.usedRewards}
              />
            </div>

            <div className="col-lg-6 col-sm-6 col-12">
              <TextField
                fullWidth
                id="outlined-text-input"
                label="No. de recompensas disponibles"
                type="number"
                name="availableRewards"
                InputProps={{ inputProps: { min: 0} }}
                value={formData.availableRewards}
                onChange={handleInputsChange}
                required
              />
            </div>
          </div>

          <FormControl fullWidth>
            {!formData.instructions && (
              <InputLabel className="pl-2">
                Instrucciones para obtener la recompensa
              </InputLabel>
            )}
            <TextareaAutosize
              value={formData.instructions}
              name="instructions"
              type="text"
              rowsMin={4}
              onChange={handleInputsChange}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="contained"
            onClick={props.onClose}
            color="primary"
            className="bg-danger text-white"
          >
            Cerrar
          </Button>

          <Button
            variant="contained"
            onClick={onSubmit}
            color="primary"
            className="bg-success text-white"
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateUserForm;

/*import React, { Component } from "react";
import { Link } from 'react-router-dom'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Formik } from 'formik'
import * as Yup from 'yup'
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import swal from 'sweetalert';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import DateFnsUtils from '@date-io/date-fns';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from '@material-ui/core/Radio';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import { Typography, FormGroup, FormControlLabel } from '@material-ui/core';
import { get, post, update, destroy } from "../../../../../services/api/http.service";
import "../../../../../App.css"

import SelectReact from 'react-select';
import makeAnimated from 'react-select/animated';

const requiredField = "Campo Requerido"
const positiveNumber = "Número debe ser positivo"

let todayDate = new Date()
const animatedComponents = makeAnimated();

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
]

class DataDisplayer extends Component {

  constructor(props) {
    super(props)
    if (props.reward) {
      const { rewards_material } = props
      this.state = { rewards_material }
      this.state = {
        rewards_material : {
          ...rewards_material
        },
        rewards_types: [],
        groups: [],
      }
    } else {
      this.state = {
        rewards_material: {
          name: "",
          description: "",
          status: false,
          rewards_types_id: 0,
          isForUsersWithoutGroup: true,
          
          //to control check
          //isWithGroups: false
        },
        rewards_types: [],
        groups: [],
      }
    }   
  }

  async componentDidMount() {
    await this.getRewardsTypes();
    await this.getGroups()
  }

  getRewardsTypes = async () => {
    try {
      const response = await get("rewards_types")
      if (response.success) {
        const data = response.rows;
        this.setState({
          ...this.state,
          ["rewards_types"]: data
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  getGroups = async () => {
    try {
      let rewardId = typeof this.state.reward.id != "undefined" && this.state.reward.id != null ? this.state.reward.id : 0
      const response = await get("groups/reward/" + rewardId)
      if (response.success) {
        //const data = response.rows.filter(({ active }) => !!active);
        let isAnyGroupSelected = response.rows.filter(row => row.isSelectedInReward == true)
        const data = response.rows
        console.log("la data ", data)
        this.setState({
          ...this.state,
          ["groups"]: data
        });

        this.setState(prevState => {
          let reward = Object.assign({}, prevState.reward);  
          reward.isForUsersWithoutGroup = isAnyGroupSelected.length > 0 ? false : true                            
          return { reward };                     
        })
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleSubmit = this.props.onSubmit

  handleCancel = (values) => {
    this.props.onCancel(values)
  }

  handleRadioButtonChange = event => {
    this.setState(prevState => {
      let reward = Object.assign({}, prevState.reward);  // creating copy of state variable jasper
      reward.isForUsersWithoutGroup = !reward.isForUsersWithoutGroup ;                     // update the name property, assign a new value                 
      return { reward };                                 // return new object jasper object
    })

    console.log("baia baia ", this.state.reward)
  }

  handleCheckGroupSelect = event => {
    console.log("el che evento ", event)
    let flag = false
    let eventGroups = event
    if(typeof event == "undefined" || event == null){
      eventGroups = []
    }
    let id;
    if(eventGroups.length > 0){
      id = eventGroups[eventGroups.length-1].id
    }
    let tempGroups = this.state.groups
    for(let group of tempGroups){
      if(typeof eventGroups.find(row => row.id == group.id) != "undefined"){
        flag = true
      }
      group.isSelectedInReward = typeof eventGroups.find(row => row.id == group.id) != "undefined" ? true : false
    }
    this.setState({
      ...this.state,
      ["groups"]: tempGroups
    });

    this.setState(prevState => {
      let reward = Object.assign({}, prevState.reward);  
      reward.isForUsersWithoutGroup = !flag ;                             
      return { reward };                     
    })

    console.log("los grupos: ", this.state.groups)
  }

  render(){
    const { rewards_material } = this.state

    return (
      <Formik
        initialValues={rewards_material}
        onSubmit={this.handleSubmit}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required(requiredField),
          description: Yup.string()
            .required(requiredField),
          rewards_types_id: Yup.number()
            .required(requiredField)
            .positive(positiveNumber),
          isForUsersWithoutGroup: Yup.boolean()
            .required(requiredField),
          isWithGroups: Yup.boolean()
            .required(requiredField)
        })}
      >
                
        {props => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          } = props     

          return (
            <form className="container-fluid" autoComplete='off' onSubmit={handleSubmit}>              
              <div className="row mb-md-3">
                <div className="col-lg-6 col-sm-6 col-12">
                  <TextField
                    fullWidth
                    id="outlined-text-input"
                    label="Nombre de la recompensa"
                    type="text"
                    name="name"
                    inputProps={{ maxLength: 128, readOnly: this.props.readOnly }}
                    onBlur={handleBlur}
                    error={errors.name && touched.name}
                    helperText={touched.name && (errors.name)}
                    className={errors.name && touched.name && "error"}
                    value={values.name}
                    onChange={handleChange}
                  />
                 </div> 
  
              </div>

              <div className="row align-items-end mb-4">                                    
                <FormControl fullWidth>
                  {!values.description && (
                    <InputLabel className="pl-2" error={errors.description && touched.description}>Descripción </InputLabel>
                  )}
                  <TextareaAutosize
                    readOnly={this.props.readOnly}
                    value={values.description}
                    name="description"
                    type="text"
                    rowsMin={4}
                    maxLength={512}
                    onChange={handleChange}
                  />
                  {errors.description && touched.description && (<FormHelperText error={errors.description && touched.description}>{errors.description}</FormHelperText>)}
                </FormControl>                                  
              </div>

              
              {this.props.readOnly &&
                <div className="row justify-content-center mt-4 pl-4">
                  <Button className='btn mr-3' style={{ backgroundColor: '#D2181F', color: 'white' }} onClick={() => { this.handleCancel(values) }} >Regresar</Button>
                </div>}
              {(!this.props.readOnly && (<div className="row justify-content-center mt-4 pl-4">
                <Button disabled={isSubmitting} className='btn mr-3' style={{ backgroundColor: '#D2181F', color: 'white' }} onClick={() => { this.handleCancel(values) }} >
                  Cancelar
                                    </Button>
                <Button disabled={isSubmitting} className='btn' style={{ backgroundColor: '#4E6E58', color: 'white' }} type="submit" onClick={() => { this.handleSubmit(values, 
                                                                                                                                                      this.state.groups)}}>
                  {isSubmitting && <span className="spinner-border spinner-border-sm"></span>}
                                    Continuar
                                    </Button>
              </div>))}
            </form>
          )
        }}
      </Formik>
    );
  }
}

export default DataDisplayer;*/
