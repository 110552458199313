import React, { useState, useEffect } from "react";
import { Formik } from 'formik'
import * as Yup from 'yup'

// Material UI
import {
  TextField,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  FormHelperText
} from "@material-ui/core";

import swal from "sweetalert";

const Form = (props) => {
  const [permissions, setPermissions] = useState([
    {
      label: 'Roles',
      section: 'roles',
      can_write: false,
      can_read: false,
      can_update: false,
      can_delete: false
    },
    {
      label: 'Equipo',
      section: 'equipo',
      can_write: false,
      can_read: false,
      can_update: false,
      can_delete: false
    },
    {
      label: 'Marcas/Empresas',
      section: 'client',
      can_write: false,
      can_read: false,
      can_update: false,
      can_delete: false
    },
    {
      label: 'Grupo',
      section: 'group',
      can_write: false,
      can_read: false,
      can_update: false,
      can_delete: false
    },
    {
      label: 'Usuarios de app',
      section: 'app_user',
      can_write: false,
      can_read: false,
      can_update: false,
      can_delete: false
    },
    {
      label: 'Puntaje',
      section: 'refund',
      can_write: false,
      can_read: false,
      can_update: false,
      can_delete: false
    },
    {
      label: 'Misiones',
      section: 'mission',
      can_write: false,
      can_read: false,
      can_update: false,
      can_delete: false
    },
    {
      label: 'Notificaciones',
      section: 'notification',
      can_write: false,
      can_read: false,
      can_update: false,
      can_delete: false
    }
  ]);
  const [role, setRole] = useState({
    name: '',
    description: ''
  });
  const [readOnly, setReadOnly] = useState(false);
  
  useEffect(() => {
    if (props.data) {
      setRole({
        name: props.data.name,
        description: props.data.description
      });

      // Set permissions
      let data_permissions = props.data.role_permissions;
      let new_permissions = [];
      data_permissions.map(permission => {
        let label = '';

        if (permission.section == 'notification') {
          label = 'Notificaciones';
        } else if (permission.section == 'app_user') {
          label = 'Usuarios de app';
        } else if (permission.section == 'group') {
          label = 'Grupo';
        } else if (permission.section == 'mission') {
          label = 'Misiones';
        } else if (permission.section == 'refund') {
          label = 'Puntaje';
        } else if (permission.section == 'client') {
          label = 'Marcas/Empresas';
        } else if (permission.section == 'equipo') {
          label = 'Equipo';
        } else if (permission.section == 'roles') {
          label = 'Roles';
        }

        new_permissions.push({
          label: label,
          section: permission.section,
          can_write: permission.can_write,
          can_read: permission.can_read,
          can_update: permission.can_update,
          can_delete: permission.can_delete
        });
      });

      setPermissions(new_permissions);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.readOnly) {
      setReadOnly(true);
    }
  }, [props.readOnly]);

  const switchChange = (label, type, event) => {
    setPermissions(permissions => 
      permissions.map(permission => {
      if (permission.label === label) {
        return {
          ...permission,
          [type]: event.target.checked
        };
      }
      return permission;
    }));
  }
  
  const handleSubmit = (values, actions) => {
    actions.setSubmitting(false);
    
    let data_permissions = [];

    permissions.map(permission => {
      let data = {}
      
      data.section = permission.section;
      data.can_write = permission.can_write;
      data.can_read = permission.can_read;
      data.can_update = permission.can_update;
      data.can_delete = permission.can_delete;

      data_permissions.push(data);
    });

    values.permissions = data_permissions;

    props.onHandleSubmit(values);
  }

  return (
    <div>
      <Formik 
        initialValues={role}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required("Campo Requerido"),
          description: Yup.string()
            .required("Campo Requerido"),
        })}
      >
        {formik => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          } = formik;

          return (
            <form className="mt-4" autoComplete='off' onSubmit={handleSubmit}>
              <div className="row mb-md-3">
                <div className="col-lg-6 col-sm-6 col-6">
                  <TextField
                    fullWidth
                    id="outlined-text-input"
                    label="Nombre"
                    type="text"
                    name="name"
                    disabled={readOnly}
                    onBlur={handleBlur}
                    error={errors.name && touched.name}
                    helpertext={touched.name && (errors.name)}
                    className={errors.name && touched.name && "error"}
                    value={values.name}
                    onChange={handleChange}
                  />
                  {errors.name && touched.name && (<FormHelperText error={errors.name && touched.name}>{errors.name}</FormHelperText>)}
                </div>
                <div className="col-lg-6 col-sm-6 col-6">
                  <TextField
                    fullWidth
                    id="outlined-text-input"
                    label="Descripcion"
                    type="text"
                    name="description"
                    disabled={readOnly}
                    onBlur={handleBlur}
                    error={errors.description && touched.description}
                    helpertext={touched.name && (errors.description)}
                    className={errors.description && touched.description && "error"}
                    value={values.description}
                    onChange={handleChange}
                  />
                  {errors.description && touched.description && (<FormHelperText error={errors.description && touched.description}>{errors.description}</FormHelperText>)}
                </div>
              </div>
              <Divider component="div" />
              <div className="row mb-md-3 mt-3">
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Secciones</TableCell>
                        <TableCell align="center">Crear</TableCell>
                        <TableCell align="center">Ver</TableCell>
                        <TableCell align="center">Editar</TableCell>
                        <TableCell align="center">Eliminar</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {permissions.map((row) => (
                      <TableRow
                        key={row.section}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.label}
                        </TableCell>
                        <TableCell align="center">
                          <Switch 
                            disabled={readOnly}
                            color="primary"
                            checked={row.can_write}
                            onChange={(event) => switchChange(row.label, 'can_write', event)}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Switch 
                            disabled={readOnly}
                            color="primary"
                            checked={row.can_read}
                            onChange={(event) => switchChange(row.label, 'can_read', event)}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Switch
                            disabled={readOnly}
                            color="primary"
                            checked={row.can_update}
                            onChange={(event) => switchChange(row.label, 'can_update', event)}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Switch
                            disabled={readOnly}
                            color="primary"
                            checked={row.can_delete}
                            onChange={(event) => switchChange(row.label, 'can_delete', event)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              <div className="row justify-content-end mt-4 pl-4">
                <button 
                  disabled={isSubmitting} 
                  type="button"
                  className='btn mr-3 missionButton' 
                  onClick={() => { window.location.href = '/app/roles' }} >
                  {
                    !readOnly ? <span>Cancelar</span> : <span>Atrás</span>
                  }
                </button>
                {
                  !readOnly && (
                    <button disabled={isSubmitting} className='btn missionButton' style={{ backgroundColor: '#28a745', color: 'white' }} type="submit" >
                      {isSubmitting && <span className="spinner-border spinner-border-sm"></span>}
                      {!isSubmitting && <span>Guardar rol</span>}
                    </button>
                  )
                }
              </div>
            </form>
          )
        }}
      </Formik>
    </div>
  );
};

export default Form;
