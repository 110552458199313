import React, { useState, useEffect } from "react";

// Material UI
import { Button } from "@material-ui/core";

// Services
import { get, post, update, destroy } from "../../../../../services/api/http.service";
import CreateUserForm from "../Components/formUser"
import { encryptData, decipherData } from "../../../../../services/api/Security.js"

// Components
import Datatable from "../../../components/routes/tables/DataTable";
import RowTable from "./RowTable";

const List = () => {
  const [dataUser, setDataUser] = useState([]);
  const [showModalUser, setShowModalUser] = useState(false)
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(true);

  const headers = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Nombre"
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Correo electronico"
    },
    { id: "role", numeric: false, disablePadding: false, label: "Rol" },
    { id: "status", numeric: false, disablePadding: false, label: "Estatus" },    
    { id: "actions", numeric: true, disablePadding: false, label: "Acciones" }
  ];

  useEffect(() => {
    const getDataEffect = async () => {
      try {
        const tempData = await get("users");
        //console.log("users", tempData.rows);
        //console.log("if ", tempData.success);
        if(tempData.success){
          let rows = await decipherData(tempData.rows)
          setDataUser(rows);
        } else {
          //console.log("No se encontraron usuarios")
        }
      } catch (err) {
        //console.log(err);
      }
    };
    getDataEffect();
    getPermissions();
  }, []);

  const getPermissions = () => {
    let permissions = JSON.parse(localStorage.getItem('permissions'));

    if (permissions === null || permissions === undefined) {
      permissions = [];
    }
    
    let permissionTemp = permissions.filter(perm => {
      if (perm.section === 'equipo') {
        return perm;
      }
    });
    console.log(permissionTemp);
    setPermissions(permissionTemp);
    setLoading(false);
  }

  return !loading && (
    <>
      <div className="container">
        <div className="row">
          {
            !permissions[0].can_read ?
              <div className="col-md-12">
                <div className="alert alert-danger" role="alert">
                  No tienes permisos para ver esta sección
                </div>
              </div> :
              <>
                <div className="col-md-3 mb-3 mt-3">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>{
                      setShowModalUser(true);
                    }}
                  >
                    Crear Usuario
                  </Button>
                </div>
                <div className="col-md-12 mr-2">
                  <Datatable
                    RowComponent={RowTable}
                    data={dataUser}
                    headers={headers}
                    title="Administración de Usuarios"
                  />
                </div>
              </>
          }
        </div>
      </div>
      <CreateUserForm 
        show={showModalUser}
        onClose={() => {
          setShowModalUser(false)
        }}
      />
    </>
  );
};

export default List;
