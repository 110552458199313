import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import InputLabel from "@material-ui/core/InputLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SelectReact from 'react-select';
import makeAnimated from 'react-select/animated';
const requiredField = "Campo Requerido";
const positiveNumber = "Número debe ser positivo";
const animatedComponents = makeAnimated();

let todayDate = new Date();

class DataDisplayer extends Component {
  constructor(props) {
    super(props);
    if (props.notification) {
      const { notification } = props;
      this.state = { notification };
      this.state = {
        notification: {
          ...notification,
        },
        groups: []
      };
    } else {
      this.state = {
        notification: {
          name: "",
          notification_typeId: 0,
          message: "",
          shipping_date: todayDate,
          // groups_id: null
        },
        groups: []
      };
    }
  }

  handleSubmit = (values, actions) => { 
    actions.setSubmitting(false);
    this.props.onSubmit(values, this.state.groups);
  }

  handleCancel = (values) => {
    this.props.onCancel(values);
  };

  handleGroupSelect = groupSelected => {
    this.setState({
      ...this.state,
      groups: groupSelected.map(group => group.id)
    });
  }

  render() {
    const { notification } = this.state;
    const { notification_types } = this.props;
    const { groups } = this.props;

    return (
      <Formik
        initialValues={notification}
        onSubmit={this.handleSubmit}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(requiredField),

          message: Yup.string().required(requiredField),
          shipping_date: Yup.date().required(requiredField),
          notification_typeId: Yup.number()
            .min(1, "Selecciona un tipo de notificación")
            .required("Campo requerido")
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;

          return (
            <form
              className="container-fluid"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <div className="row mb-md-3">
                <div className="col-lg-6 col-sm-6 col-12">
                  <TextField
                    autoFocus={true}
                    fullWidth
                    id="outlined-text-input"
                    //label="Nombre"
                    label="Nombre"
                    type="text"
                    //name="name"
                    name="name"
                    inputProps={{
                      maxLength: 128,
                      readOnly: this.props.readOnly,
                    }}
                    onBlur={handleBlur}
                    error={errors.name && touched.name}
                    helperText={touched.name && errors.name}
                    className={errors.name && touched.name && "error"}
                    value={values.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <FormControl fullWidth error={
                        Boolean(
                        errors.notification_typeId &&
                        touched.notification_typeId)
                      }>
                    <InputLabel>Tipo de Notificación</InputLabel>
                    <Select
                      fullWidth
                      required
                      id="outlined-text-input"
                      label="Tipo de Notificación"
                      type="text"
                      name="notification_typeId"
                      inputProps={{
                        maxLength: 128,
                        readOnly: this.props.readOnly,
                      }}
                      onBlur={handleBlur}
                      error={
                        errors.notification_typeId &&
                        touched.notification_typeId
                      }
                      helperText={
                        touched.notification_typeId &&
                        errors.notification_typeId
                      }
                      className={
                        errors.notification_typeId &&
                        touched.notification_typeId &&
                        "error"
                      }
                      value={values.notification_typeId}
                      onChange={handleChange}
                    >
                      {notification_types
                        ? notification_types.map((notification_type) => {
                            return (
                              <MenuItem value={notification_type.id}>
                                {" "}
                                {notification_type.name}{" "}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Select>
                    {
                      errors.notification_typeId &&
                      touched.notification_typeId && <FormHelperText>{ errors.notification_typeId }</FormHelperText>
                    }
                  </FormControl>
                </div>
              </div>
              <div className="row mb-md-3">
                {values.notification_typeId === 1 ? this.props.readOnly ? (<div className="col-lg-12 col-sm-12 col-12">
                <TextField
                    autoFocus={true}
                    fullWidth
                    label="Grupos"
                    type="text"
                    inputProps={{
                      maxLength: 128,
                      readOnly: true,
                    }}
                    onBlur={handleBlur}
                    value={this.state.notification.groups_string}
                  />
                </div>) : (
                  <div className="col-lg-12 col-sm-12 col-12">
                    <SelectReact
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      placeholder="Seleccionar grupos"
                      isMulti
                      onChange={this.handleGroupSelect}
                      isDisabled={this.props.readOnly}
                      options={groups.map( group => {
                          return {value: group.name, label: group.name, id: group.id}
                      })}
                    />
                  </div>
                ) : null}
              </div>
              <div className="row align-items-end mb-4">
                <FormControl fullWidth>
                  {!values.message && (
                    <InputLabel
                      className="pl-2"
                      error={errors.message && touched.message}
                    >
                      Mensaje{" "}
                    </InputLabel>
                  )}
                  <TextareaAutosize
                    readOnly={this.props.readOnly}
                    value={values.message}
                    name="message"
                    type="text"
                    rowsMin={4}
                    maxLength={512}
                    onChange={handleChange}
                  />
                  {errors.message && touched.message && (
                    <FormHelperText error={errors.message && touched.message}>
                      {errors.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>

              {this.props.readOnly && (
                <div className="row justify-content-center mt-4 pl-4">
                  <Button
                    className="btn mr-3"
                    style={{ backgroundColor: "#0f2647", color: "white" }}
                    onClick={() => {
                      this.handleCancel(values);
                    }}
                  >
                    Regresar
                  </Button>
                </div>
              )}
              {!this.props.readOnly && (
                <div className="row justify-content-center mt-4 pl-4">
                  <Button
                    disabled={isSubmitting}
                    className="btn mr-3"
                    style={{ backgroundColor: "#0f2647", color: "white" }}
                    onClick={() => {
                      this.handleCancel(values);
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    className="btn"
                    style={{ backgroundColor: "#ffb133", color: "white" }}
                    type="submit"
                  >
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    Continuar
                  </Button>
                </div>
              )}
            </form>
          );
        }}
      </Formik>
    );
  }
}

export default DataDisplayer;
