import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import swal from "sweetalert";

import Form from '../components/form'
import { post } from "../../../../../services/api/http.service";

const Create = () => {

    const handleSubmit = async (values) => {
        values.client_id = localStorage.getItem("client_id");
        try {
            const response = await post("roles", values);

            swal({
                title: "Rol registrado",
                text: "El rol se ha registrado correctamente",
                icon: "success",
                confirmButtonColor: "#ffb133",
            })
            .then(() => window.location.href = "/app/roles/")
        } catch (error) {
            swal({
                title: "Error",
                text: 'Ah ocurrido un error al intentar crear el rol, por favor intenta de nuevo',
                icon: "error",
                confirmButtonColor: "#ffb133",
            })
        }
    }

    return (
        <Card className="container-fluid p-4 pl-5">
            <div className='d-flex m-4 align-middle'>
                <h1>Añadir rol</h1>
            </div>
            <div className='ml-5 mr-5'>
                <span className='normalText'>
                Personaliza los accesos y funciones de tu equipo con facilidad. Crea roles específicos que se ajusten a cada tarea y necesidad del negocio, 
                optimizando el flujo de trabajo y manteniendo segura la información. Ideal para ajustar responsabilidades y mejorar la productividad.
                </span>
                
                <Form onHandleSubmit={handleSubmit} />
            </div>
        </Card>
    );
}

export default Create;