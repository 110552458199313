import React from "react";
//import Datatable from '../../../../../components/Datatable'
//import Datatable from '../../../../routes/table/routes/data/Components/DataTable'
import Datatable from "../../../components/routes/tables/DataTable";
import RowTable from "./rowTable";
import fetchData from "../../../../../services/api/FetchData";
//import Loader from "../../../../../components/Loader"
import Loader from "../../../../../components/CircularProgress";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { get, post, update, destroy } from "../../../../../services/api/http.service";

export default class List extends React.Component {
  state = {
    data: [],
    trademarks: [],

    headers: [
      { id: "id", numeric: false, disablePadding: false, label: "ID" },
      { id: "url", numeric: false, disablePadding: false, label: "URL" },
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: "Nombre",
      }
      ,
    //   {
    //     id: "limit_registers",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Límite de Inscritos",
    //   },
    //   {
    //     id: "reward",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Recompensa",
    //   },
    //   {
    //     id: "status",
    //     numeric: false,
    //     disablePadding: false,
    //     label: "Estado",
    //   },
      {
        id: "actions",
        numeric: true,
        disablePadding: false,
        label: "Acciones",
      },
    ],
  };

  setData = async (route, dataName) => {
    try {
      const response = await get(route)
      if (response.success) {
        const data = response.rows;

        this.setState({
          ...this.state,
          [dataName]: data,
          dataFiltred: data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  async getTrademarks(){
    try{
        let response = await get("trademarks")
        if(response.success){
            //trademarks = response.rows
            this.setState({
                ...this.state,
                trademarks: response.rows
            });
        }
    } catch(err){
        console.log("Error: ", err)
    }
  }



  async componentDidMount() {
    await this.getTrademarks()
    await this.setData(`fan_pages`, "data");
  }

  reloadData = () => this.props.history.push("/app/fan_pages/");

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-3 mb-3 mt-3">
            <Button>
              <Link className="btn btn-primary " to={`/app/fan_pages/create`}>
                Crear Fan Page
              </Link>
            </Button>
          </div>
          <div className="col-md-9">
          </div>
          <div className="col-md-12  mr-2">
            <Datatable
              RowComponent={RowTable}
              data={this.state.data}
              headers={this.state.headers}
              reloadData={this.reloadData}
              title="Fan Pages"
            />
          </div>
        </div>
      </div>
    );
  }
}

