import React, { useState } from "react";
import {
  Menu,
  MenuItem,
} from "@material-ui/core";
import swal from "sweetalert";
import { put } from "../../../../../services/api/http.service";
import Form from "../components/form"
import { decipherData } from "services/api/Security";
const jwt = require("jsonwebtoken");

const Options = (props) => {
  const { menuState, anchorEl, handleRequestClose, data} = props;
  const [showAdd, setShowAdd] = useState(false)
  const [showSub, setShowSub] = useState(false)

  const balanceSubstraction = async (form) => {
    console.log("Register params: ", form);

    const body = {
      ...form,
    };
    //eliminar id del body para no afectar registro
    delete body["id"]
    try{
      let tokenAPI = localStorage.getItem('tokenAPI');
      jwt.verify(tokenAPI , process.env.REACT_APP_JWT_SECRET, async (err, data) => {
        if(err){
            console.log("Falla: " + err)
            swal({
              title: "Error",
              text: `Vuelve a iniciar sesión por favor.`,
              icon: "error",
              confirmButtonColor: "#ffb133",
            })
            return
        } else {
          let userData = await decipherData(data.data)  
          if(typeof userData.id == "undefined" || userData.id === null){
            swal({
              title: "Error",
              text: `No pudimos encontrar tu id, vuelve a iniciar sesión por favor.`,
              icon: "error",
              confirmButtonColor: "#ffb133",
            })
            return
          }

          body.user_id = parseInt(userData.id)
          let response = await put("app_users/balance/substraction/" + form.id, body)
          if(response.success){
            swal({
              title: "Actualización exitosa",
              text: response.message,
              icon: "success",
              confirmButtonColor: "#ffb133",
            })
            .then(() => {
              //getData()
              //window.location.reload(false);
              //window.location.reload()
              window.location.href = window.location.href;
            });
          } else {
            swal({
              title: "Error",
              text: `Ups, hubo un problema \n ${response.message}`,
              icon: "error",
              confirmButtonColor: "#ffb133",
            })
          }
        }
      })
    }catch(err){
      console.log("Error: ", err)
      swal({
        title: "Error",
        text: `Ups, hubo un problema \n ${err}`,
        icon: "error",
        confirmButtonColor: "#ffb133",
      })
    } 
  }

  const balanceAddition = async (form) => {
    console.log("Register params: ", form);

    const body = {
      ...form,
    };
    //eliminar id del body para no afectar registro
    delete body["id"]
    try{
      let tokenAPI = localStorage.getItem('tokenAPI');
      jwt.verify(tokenAPI , process.env.REACT_APP_JWT_SECRET, async (err, data) => {
        if(err){
            console.log("Falla: " + err)
            swal({
              title: "Error",
              text: `Vuelve a iniciar sesión por favor.`,
              icon: "error",
              confirmButtonColor: "#ffb133",
            })
            return
        } else {
          let userData = await decipherData(data.data)  
          if(typeof userData.id == "undefined" || userData.id === null){
            swal({
              title: "Error",
              text: `No pudimos encontrar tu id, vuelve a iniciar sesión por favor.`,
              icon: "error",
              confirmButtonColor: "#ffb133",
            })
            return
          }

          body.user_id = parseInt(userData.id)
          let response = await put("app_users/balance/addition/" + form.id, body)
          if(response.success){
            swal({
              title: "Actualización exitosa",
              text: response.message,
              icon: "success",
              confirmButtonColor: "#ffb133",
            })
            .then(() => {
              //getData()
              //window.location.reload(false);
              //window.location.reload()
              window.location.href = window.location.href;
            });
          } else {
            swal({
              title: "Error",
              text: `Ups, hubo un problema \n ${response.message}`,
              icon: "error",
              confirmButtonColor: "#ffb133",
            })
          }
        }
      })

      return

      let response = await put("app_users/balance/addition/" + data.id, body)
      if(response.success){
        swal({
          title: "Actualización exitosa",
          text: response.message,
          icon: "success",
          confirmButtonColor: "#ffb133",
        })
        .then(() => {
          //getData()
          //window.location.reload(false);
          //window.location.reload()
          window.location.href = window.location.href;
        });
      } else {
        swal({
          title: "Error",
          text: `Ups, hubo un problema \n ${response.message}`,
          icon: "error",
          confirmButtonColor: "#ffb133",
        })
      }
    }catch(err){
      swal({
        title: "Error",
        text: `Ups, hubo un problema \n ${err}`,
        icon: "error",
        confirmButtonColor: "#ffb133",
      })
    }
  };

  const setModalState = (name, open) => {
    console.log("dentro del set modal")
    switch(name){
      case "Add":
        setShowAdd(open)
        break;
      case "Sub":
        setShowSub(open)
        break;
      default:
        break;
    }
  }

  let permissions = JSON.parse(localStorage.getItem('permissions'));

  if (permissions === null || permissions === undefined) {
    permissions = [];
  }

  let permission = permissions.filter(perm => {
    if (perm.section === 'refund') {
      return perm;
    }
  });

  return (
    <React.Fragment>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={menuState}
        onClose={handleRequestClose}
        MenuListProps={{
          style: {
            width: 150,
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        {
          (permission.length > 0 && permission[0].can_update) &&
          <MenuItem onClick={() => {setModalState("Add", true)}}>
            <i className="zmdi zmdi-plus-circle" />
            <span className="ml-2">Asignar coins</span>
          </MenuItem>
        }
        {
          (permission.length > 0 && permission[0].can_update) &&
          <MenuItem onClick={() => {setModalState("Sub", true)}}>
            <i className="zmdi zmdi-minus-circle" />
            <span className="ml-2">Retirar coins</span>
          </MenuItem>
        }
      </Menu>

      {showAdd ? <Form 
        show={showAdd}
        title={"Asignar Colombo Coins"}
        onSave={balanceAddition}
        readOnly={false}
        data={data}
        onClose={() => { setModalState("Add", false) }}
      /> : null } 

      {showSub ? <Form 
        show={showSub}
        title={"Retirar Colombo Coins"}
        onSave={balanceSubstraction}
        readOnly={false}
        data={data}
        onClose={() => { setModalState("Sub", false) }}
      /> : null}


    </React.Fragment>
  );
};

export default Options;
