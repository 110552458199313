import React from 'react'
import Form from '../Components/form'
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Loader from "../../../../../components/CircularProgress"
import { get, post, update, destroy } from "../../../../../services/api/http.service";

export default class Create extends React.Component {

    state = {
        reward: null
    }

    getData = async (route, dataName) => {
        const response = await get(route)
        if (response.success) {
            this.setState({
                [dataName]: response.rows[0]
            })
        }
    }   

    async componentDidMount() {
        await this.getData(`rewards/${this.props.match.params.id}`, "reward")
    }


    handleCancel = () => this.props.history.push("/app/rewards")

    render() {        
        return (
            this.state.reward ?
            <Card className="container-fluid p-4 pl-5">
                <h1>Registrar recompensa</h1>
                <Divider className="mb-4" />
                <Card className="col-12 p-4 pl-5 mr-4">
                    <Form onCancel={this.handleCancel} reward={this.state.reward} onSubmit={this.handleSubmit} readOnly={true}/>
                </Card>
            </Card>
            :
            <Loader />
        )
    }
}