import React from 'react'
import Form from '../Components/form'
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Loader from "../../../../../components/CircularProgress"
import { get, post, put, destroy } from "../../../../../services/api/http.service";

export default class View extends React.Component {

    state = {
        fan_page: null,
        trademarks: []
    }

    getData = async (route, dataName) => {
        const response = await get(route)
        if (response.success) {
            this.setState({
                [dataName]: response.rows[0]
            })
        }
    }   
    
    async getTrademarks(){
        try{
            let response = await get("trademarks")
            if(response.success){
                //trademarks = response.rows
                this.setState({
                    ...this.state,
                    trademarks: response.rows
                });
            }
        } catch(err){
            console.log("Error: ", err)
        }
    }


    async componentDidMount() {
        await this.getTrademarks()
        await this.getData(`fan_pages/${this.props.match.params.fan_pageId}`, "fan_page")
    }

    handleCancel = () => this.props.history.push("/app/fan_pages")

    render() {
        console.log(this.state)
        return (
            this.state.fan_page ?
                <Card className="container-fluid p-4 pl-5">
                    <h1>Ver Fan Page</h1>
                    <Divider className="mb-4" />
                    <Card className="col-12 p-4 pl-5 mr-4">
                        <Form onCancel={this.handleCancel} trademarks={this.state.trademarks} 
                            fan_page={this.state.fan_page} readOnly={true} />
                    </Card>
                </Card>
                :
                <Loader />
        )
    }
}