
//const jwt = require("jsonwebtoken");
import { encryptData, decipherData } from "./Security.js"
import fetchData from "./FetchData.js"
const jwt = require("jsonwebtoken")

const login = async (email, password, url) => {
  try {            
    
    let dataToEncrypt = {
      email : email,
      password: password
    }

    //console.log("Data dataToEncrypt", dataToEncrypt)
    let dataEncrypted = await encryptData(dataToEncrypt);
    //console.log("Data encrypted", dataEncrypted)
    
    let body = {
      data: dataEncrypted,
    }

    var token = jwt.sign(body, process.env.REACT_APP_AUTHENTICATION_SECRET, {
      expiresIn: "10s" // expires in 24 hours
    })  

    console.log("why is null token: ", token)

    const options = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
       "Authorization": "Bearer " + token
      }
    }  

    console.log("la url: ", url)
    let response = await fetchData(url, options);
    console.log("Response: ", response);
    let jsonResponse = await response.json();
    //console.log("JSON response: ", jsonResponse);    

    if(typeof jsonResponse.token != "undefined" && jsonResponse.token != null){
      localStorage.setItem('tokenAPI', jsonResponse.token); 
    }
    return jsonResponse;
} catch (err) {
    //console.log("Response post error: ", err);
    throw err;
}

}

const get = async (url) => {
    try {            
        const options = {
          method: "GET",
        };
        let response = await fetchData(url, options);
        let jsonResponse = await response.json();   
        return jsonResponse;
    } catch (err) {
        console.log("Response get error: ", err);
        throw err;
    }
}

const post = async (url, body) => {
  try {            
      const options = {
        method: "POST",
        body: JSON.stringify(body),
      };
  
      let response = await fetchData(url, options);
      let jsonResponse = await response.json();   
      return jsonResponse;
  } catch (err) {
      console.log("Response post error: ", err);
      throw err;
  }
}

const put = async (url, body) => {
  try {            
      const options = {
        method: "PUT",
        body: JSON.stringify(body),
      };
  
      let response = await fetchData(url, options);
      let jsonResponse = await response.json();;    
      return jsonResponse;
  } catch (err) {
      console.log("Response update error: ", err);
      throw err;
  }
}

const destroy = async (url) => {
  try {            
      const options = {
        method: "DELETE",
      };
  
      let response = await fetchData(url, options);
      let jsonResponse = await response.json();   
      return jsonResponse;
  } catch (err) {
      console.log("Response delete error: ", err);
      throw err;
  }
}

export {
  login,
  get,
  post,
  put,
  destroy
};
