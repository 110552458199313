import React from 'react'
import Form from '../Components/form'
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import fetchData from "../../../../../services/api/FetchData"
import swal from 'sweetalert';
import { get, post, update, destroy } from "../../../../../services/api/http.service";

export default class Create extends React.Component {
 
    handleSubmit = async (values) => {
        console.log("enviado")
        try{
            //poner time en 0 del datetime
            //console.log("los values jajajaja: ", values, " y los grupos: ", groups)
            let data = Object.assign({},values)
            //console.log("Mission")
            console.log(data)
            data.client_id = localStorage.getItem("client_id")

            const response = await post("groups", data)
            console.log("\n\n\n", response)
            if (!response.success) {
                swal({
                    title: "Error",
                    text: "No se ha podido registrar el grupo",
                    icon: "error",
                    confirmButtonColor: "#ffb133",
                })
                return
            }
            swal({
                title: "Grupo registrado",
                text: "El grupo se ha registrado correctamente",
                icon: "success",
                confirmButtonColor: "#ffb133",
            })
            .then(() =>  window.location.href = "/app/groups/"/*this.props.history.push(`/app/groups/`)*/)
        }catch(err){
            swal({
                title: "Error",
                text: err,
                icon: "error",
                confirmButtonColor: "#ffb133",
            })
        }
    }

    handleCancel = () => this.props.history.push("/app/groups")

    
    render() {        
        return (
            <Card className="container-fluid p-4 pl-5">
                <h1>Registrar grupo</h1>
                <Divider className="mb-4" />
                <Card className="col-12 p-4 pl-5 mr-4">
                    <Form onCancel={this.handleCancel} onSubmit={this.handleSubmit}  handleSubmit={this.handleSubmit} />
                </Card>
            </Card>
        )
    }
}