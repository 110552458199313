// React
import React, { useState, useEffect } from "react";

// Material UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  FormControl,
  InputLabel,
  Input,
} from "@material-ui/core";


// Utils
import swal from "sweetalert";

// Services
import {
  get
} from "../../../../../services/api/http.service";

const ModalForm = (props) => {

  const [formData, setFormData] = useState({
    usedRewards: 0,
    availableRewards: 0,
    rewardsToAdd: 0,
    file: null
  });

  const handleInputsChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    props.onClose();
    props.onSubmit(formData);
    setFormData({...formData, 
      usedRewards: 0,
      availableRewards: 0,
      rewardsToAdd: 0,
    })
  };

  useEffect(() => {
    if (props.show == true) {
      getRewardMaterialDetail();
    }
  }, [props.show]);

  const getRewardMaterialDetail = async () => {
    try {
      const response = await get("rewards_instant_wins/details/" + props.id);
      if (response.success) {
        setFormData({... formData, usedRewards: response.usedRewards, availableRewards: response.availableRewards});
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  }; 

  const handleChangeFile = async (event) => {

    if(typeof event.target.files == "undefined" && event.target.files.length === 0){
        return
    }
    let file = event.target.files[0]
    //to read csv
    // var reader = new FileReader();
    // reader.onload = function(e) {
    //     //get JSON array
    //     let csvArray = csvJSON(reader.result)
    //     console.log("no entiendo esto ", csvArray[csvArray.length-1])
    //     console.log("no entiendo esto ", Object.keys(csvArray[csvArray.length-1]))
    //     console.log("no entiendo esto ", csvArray[csvArray.length-1]["code"])
    //     return reader.result
    //     let z = []
    //     for(let iCont = 0; iCont<csvArray.length; iCont++){
    //       z.push({code: csvArray[iCont]["code"]})
    //     }
    //     /*console.log("el maldito row: ",(Object.keys(csvArray)) )
    //     for(let row of csvArray){
    //       let indexKey = Object.keys(row)
    //       indexKey = indexKey[0]          
    //       z.push({code: row[indexKey].code})
    //     }*/
    //     console.log("7777777777777777 ", csvArray[0]["0"])
    //     console.log("666666666666666 ", z)
    //     let struct = ["code"]
    //     let isValid = validateCSVStruct(struct, csvArray)
    //     if(!isValid.result){
    //       props.onClose();
    //       swal("Error", "El archivo contiene " + isValid.error_number + " errores." , "error")
    //       return
    //     }

    //     if(typeof props.id == "undefined"){
    //       props.onClose();
    //       swal("Error", "No se proporcionó el id de la recompensa, contacta a soporte.", "error")
    //       return
    //     }

    //     setFormData({... formData, rewardsToAdd: csvArray.length, file: file, rewards_id: props.id})
    // }
    // reader.readAsText(file);

    let tempArray = await parse(file)
    /*for(let iCont = 1; iCont<tempArray.length; iCont++){

    }*/
    let headers = tempArray[0]
    if(headers.split(",").length !== 1 || headers.split(",")[0] !== "code"){
      props.onClose();
      swal({
          title: "Error",
          text: "No existe la columna 'Code' en el archivo o se insertaron columnas adicionales",
          icon: "error",
          confirmButtonColor: "#ffb133",
      })
      return
    }

    let errorArray = tempArray.filter(row => row.split(",").length !== 1 && typeof row.code === "undefined" || row.code === null || row.code === "null" || row.code === "")
    if(errorArray.length !== 0){
      props.onClose();
      swal({
          title: "Error",
          text: "El archivo contiene " + errorArray.length + " errores." ,
          icon: "error",
          confirmButtonColor: "#ffb133",
      })
      return
    }
  
    if(typeof props.id == "undefined"){
      props.onClose();
      swal({
          title: "Error",
          text: "No se proporcionó el id de la recompensa, contacta a soporte.",
          icon: "error",
          confirmButtonColor: "#ffb133",
      })
      return
    }

    setFormData({... formData, rewardsToAdd: tempArray.length-1, file: file, rewards_id: props.id})
  };

  function parse(file) {
    // Always return a Promise
    return new Promise((resolve, reject) => {
      let content = '';
      const reader = new FileReader();
      // Wait till complete
      reader.onloadend = function(e) {
        content = e.target.result;
        const result = content.split(/\r\n|\n/);
        resolve(result);
      };
      // Make sure to handle error states
      reader.onerror = function(e) {
        reject(e);
      };
      reader.readAsText(file);
    });
  }

  const validateCSVStruct = (struct, values) => {
    let errorMessage = "Se tienen errores en las lineas:"
    let error_number = 0
    let isValid = true

    console.log("ijnviurenvuirninv ", values[0])
    let u = Object.assign({}, values)
    console.log("ijnviurenvuirninv ", Object.values(values[0]))
    console.log("ijnviurenvuirninv ", values[0].code)
    console.log("///////////////////// ", u)
    console.log("******************* ", u.code)
    let arrayBadCode = values.filter(row => typeof row.code === "undefined" || row.code === null || row.code === "null" || row.code === "")
console.log("gdgdgdggd ", arrayBadCode.length )


    /*for(let iCont = 0; iCont<values.length; iCont++){
      let value = values[iCont]
      for(let key of struct){
        console.log("33333333 ",  Object.keys(value).length != struct.length)
        console.log("55555555555555 ", value)
        if(typeof value[key] === "undefined" || value[key] === null || value[key] === "null" || value[key] === "" || Object.keys(value).length != struct.length){
          isValid = false
          errorMessage += " " + iCont+1 + ","
          error_number++
        }
      }
    }*/

    return {result: isValid, errorMessage: errorMessage, error_number: error_number}
  }

  /*const validateCSVStruct = (struct, values) => {
    let errorArray = values.filter(value => {
      return struct.some(key => {
        console.log("llave: ", key)
        console.log("valor: ", value[key])
        console.log("object: ", value.end_date)
        return typeof value[key] === "undefined" || value[key] === null || value[key] === "null" || value[key] === ""
      })

    //   return struct.map(key => {
    //     console.log("llave: ", key)
    //     console.log("valor: ", value[key])
    //     console.log("object: ", value.end_date)
    //     return typeof value[key] === "undefined" || value[key] === null || value[key] === "null" || value[key] === ""
    //   })
    // })
    })

    if(errorArray.length === 0){
      return {result: true}
    }

    //let messageError = "Se han encontrado errores en las siguientes líneas: " + errorArray.length
    for(let error of errorArray){
      let key = Object.keys(error).find(row => typeof error[row] !== "undefined" && error[row] !== null && error[row] !== "null" && error[row] !== "")
      if(typeof key !== "undefined"){
        let position = errorArray.map(function(e) { return e[key]; }).indexOf(error[key]);
        console.log("el index de error: ", position)
      }
    }

    return {result: false, numberErrors: errorArray.length}

  }*/



  function csvJSON(csv) {
    const lines = csv.split("\n");
    const result = [];
    const headers = lines[0].split(",");

    for (let i = 1; i < lines.length; i++) {
      if (!lines[i]) continue;
      const obj = {};
      const currentline = lines[i].split(",");

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }
      result.push(obj);
    }
    return result;
  }

  return (
    <>
      <Dialog
        open={props.show}
        TransitionComponent={Slide}
        onClose={props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Instant wins</DialogTitle>
        <DialogContent>
          <div className="row mb-md-3">
            <div className="col-lg-6 col-sm-6 col-12">
              <TextField
                fullWidth
                label="No. de recompensas disponibles"
                type="number"
                inputProps={{ readOnly: true }}
                value={formData.availableRewards}
              />
            </div>

            <div className="col-lg-6 col-sm-6 col-12">
              <TextField
                fullWidth
                id="outlined-text-input"
                label="No. de recompensas a subir"
                type="number"
                InputProps={{ readOnly: true }}
                value={formData.rewardsToAdd}
                onChange={handleInputsChange}
              />
            </div>
          </div>

          <FormControl>
            <InputLabel>Inserta tu csv con los Instant wins</InputLabel>
            <div style={{paddingBottom: "45px"}}></div>
            <Input
              type="file"
              inputProps={{
                accept: ".csv",
              }}
              onChange={handleChangeFile}
              disableUnderline={true}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="contained"
            onClick={props.onClose}
            color="primary"
            className="bg-danger text-white"
          >
            Cerrar
          </Button>

          <Button
            variant="contained"
            onClick={onSubmit}
            color={formData.file == null ? "gray" : "primary"}
            className={formData.file == null ? "text-white" : "bg-success text-white" }    
            disabled={formData.file == null ? true : false}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalForm;
